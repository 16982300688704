<template>
  <div>
    <div itemscope itemtype="https://schema.org/WebSite">
      <meta itemprop="url" content="https://www.premiumqualitydrywall.com/"/>
      <meta itemprop="name" content="Premium Quality Drywall"/>
    </div>
    <v-container>
      <v-row class="mt-16">
        <v-col align="center">
          <p class="text-h5">
            We offer a variety of services. All jobs, from big to small. We've got you covered!
          </p>
          <p class="text-h5">
            We can handle all of your drywalling needs and work in the entire San Fernando Valley and beyond!
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" offset="3">
          <v-img :src="getAssetImg('/assets/logo-color-crop_wcl7z9.webp')"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

export default {
  components: {},
  name: "HomeView",
  metaInfo: {
    title: 'Home',
    link: [{rel: 'canonical', href: 'https://www.premiumqualitydrywall.com'}]
  },
};
</script>

<style scoped>
</style>
